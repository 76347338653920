<!--====================================================
                      FOOTER
======================================================--> 
    <footer > 
        <div id="footer-s1" class="footer-s1">
          <div class="footer">
            <div class="container">
              <div class="row">
                <!-- About Us -->
                <div class="col-md-3 col-sm-6 ">
                  <div ><img src="../assets/img/logo-trans5.png" alt="" class="img-fluid"></div>
                  
                </div>
                <!-- End About Us -->

                <!-- Recent News -->
                <div class="col-md-3 col-sm-6 " >
                  <div class="heading-footer"style="display: none;"><h2>Meny</h2></div>
                  <ul class="list-unstyled link-list"style="display: none;">
                    <li class="nav-item" *ngFor="let itm of mainNavData">
                      <a routerLink="{{itm.acf.link}}">{{itm.acf.linkname}} <i class="fa fa-angle-right" aria-hidden="true"></i></a> 
                    </li> 
                    
                  </ul>
                </div>
                <!-- End Recent list -->

                <!-- Recent Blog Entries -->
                <div class="col-md-3 col-sm-6 ">
                  
                </div>
                <!-- End Recent Blog Entries -->

                <!-- Latest Tweets -->
                <div class="col-md-3 col-sm-6">
                  <div class="heading-footer" style="display: none;"><h2>Adress</h2></div>
                  <address class="address-details-f">
                    Gunnarsgårdsvägen 12<br>
                    523 72 - Timmele <br>
                    Växel 0321-53 02 80 <br>
                    E-post: <a href="mailto:info@timmele.se" class="">info@timmele.se</a>
                  </address>  
                  <ul class="list-inline social-icon-f top-data">
                    <!-- <li><a href="#" target="_empty"><i class="fa top-social fa-facebook"></i> Facebook</a></li> -->
                    <!-- <li><a href="#" target="_empty"><i class="fa top-social fa-twitter"></i></a></li>
                    <li><a href="#" target="_empty"><i class="fa top-social fa-google-plus"></i></a></li>  -->
                  </ul>
                </div>
                <!-- End Latest Tweets -->
              </div>
            </div><!--/container -->
          </div> 
        </div>

        <div id="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <div id="footer-copyrights">
                            <p>Copyrights &copy; 2022 All Rights Reserved by timmele.com. <a href="#"style="display: none;">Privacy Policy</a> <a href="#"style="display: none;">Terms of Services</a></p>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
        <a href="" (click)="onClickScroll('headerblock')" id="back-to-top" class="btn btn-sm btn-green btn-back-to-top smooth-scrolls hidden-sm hidden-xs" title="home" role="button">
            <i class="fa fa-angle-up"></i>
        </a>
    </footer>