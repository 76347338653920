
<!--====================================================
                       Om oss head
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Om oss</h1>
      <p><a [routerLink]="[]" fragment="Frakt" class="smooth-scrolls">Frakt och montering</a> - <a [routerLink]="[]" fragment="kvalitetochmiljo" class="smooth-scrolls">Kvalitet och miljö</a> - <a [routerLink]="[]" fragment="foretaget" class="smooth-scrolls">Företaget</a></p>
    </div><!--/end container-->
  </div> 
  
  <!--====================================================
                        Produktion
  ======================================================-->
  <section id="produktion" class="business-growth-p1 ">
    <div class="container">    
      <div class="row" style="padding-bottom:2rem;padding-top:6rem;">
        <div class="col-md-6">
          <div class="single-news-p1-cont" >          
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h2>Vår produktion</h2>
              <div class="heading-border-light"></div>
              <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. 
                </p><p>Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
              </p>
              <p>Certifikatnummer SC 0212-10</p>            
            </div>
          </div>
        </div>
        <div class="col-md-6">          
            <img src="../assets/img/Bildertakstolsfabriken/DSC_5504.jpg" class="img-fluid" alt="...">          
        </div> 
      </div>
    </div>
  </section>    
  
  <section>    
    <div class="container">
        <!-- <hr>     -->
        <div class="row mb-5  pb-5">            
        <div class="col-md-3 col-sm-6" >
          <div class="story-descb1">
              <img src="../assets/img/Bildertakstolsfabriken/DSC_5509.jpg" class="img-fluid" alt="...">                     
          </div>          
        </div>        
        <div class="col-md-3 col-sm-6" >
            <div class="story-descb1">
                <img src="../assets/img/Bildertakstolsfabriken/DSC_5517.jpg" class="img-fluid" alt="...">                     
            </div>          
          </div>      
        <div class="col-md-3 col-sm-6 "> 
            <div class="story-descb1">
                <img src="../assets/img/Bildertakstolsfabriken/DSC_5519.jpg" class="img-fluid" alt="...">                                   
            </div>
        </div>    
        <div class="col-md-3 col-sm-6 "> 
            <div class="story-descb1">
                <img src="../assets/img/Bildertakstolsfabriken/DSC_5564.jpg" class="img-fluid" alt="...">                                   
            </div>
        </div>              
      </div>    
      
    </div> 
  </section>
  
  <!--====================================================
                          Frakt och montering Siddelare
  ======================================================-->
  <div id="Frakt" class="home-p pages-head1  text-center" >
    <div class="container" >
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Frakt och montering</h1>
      <p></p>
    </div><!--/end container-->
  </div> 
  
  <!--====================================================
                        Frakt och montering
  ======================================================-->
  <section id="produktion" class="business-growth-p1 ">
    <div class="container">    
      <div class="row" style="padding-bottom:2rem;padding-top:6rem;">
        <div class="col-md-6">
          <div class="single-news-p1-cont" >        
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h3>Frakt och montering</h3>
              <div class="heading-border-light"></div>
              <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
              </p>
                <p>Certifikatnummer SC 0212-10</p>
              <p>Fyll i alla uppgifter för offertförfrågan</p>
              
            </div>
          </div>
      </div> 
      <div class="col-md-6">
        <img src="../assets/img/Bildertakstolsfabriken/takstolmontage2.jpg" class="img-fluid" alt="...">     
        
      </div>
      </div>
    </div>
  </section>    
  
  <section >    
      <div class="container">
          
          <div class="row mb-5  pb-5">            
          <div class="col-md-4 col-sm-6" >
            <div class="story-descb1"> 
             <img src="../assets/img/Bildertakstolsfabriken/IMG_1262.JPG" class="img-fluid" alt="...">                       
            </div>          
          </div>        
          <div class="col-md-4 col-sm-6" >
              <div class="story-descb1">
                  <img src="../assets/img/Bildertakstolsfabriken/IMG_1245.JPG" class="img-fluid" alt="...">                     
              </div>          
            </div>      
          <div class="col-md-4 col-sm-6 "> 
              <div class="story-descb1">
                   <img src="../assets/img/Bildertakstolsfabriken/IMG_1281.JPG" class="img-fluid" alt="...">                                  
              </div>
          </div>    
          <hr>    
        </div>    
        
      </div> 
  </section>
  
  <!--====================================================
                         Kvalitet och miljö
  ======================================================-->
  <div id="kvalitetochmiljo" class="home-p pages-head1  text-center" >
    <div class="container" >
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Kvalitet och miljö</h1>
      <p>Miljötänkande - i alla led</p>
    </div><!--/end container-->
  </div> 
  
  <!--====================================================
                        Miljövänlig tillverkningsprocess.
  ======================================================-->
  <section id="husstommar" class="business-growth-p1  bg-gray">
      <div class="container">
        
        <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
          <div class="col-md-6">
            <div class="single-news-p1-cont" >
              
              <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                  <h2>Miljövänlig tillverkningsprocess. </h2>
                  <div class="heading-border-light"></div>
                  <p>
                      Timmele Takstolsfabrik AB har en i särklass mycket miljövänlig tillverkningsprocess. Inga kemikalier används i produktionen och därmed inga miljöfarliga utsläpp. Det råmaterialspill som uppstår i produktionen sorterar vi för att sedan återvinna. Virkesspillet eldar vi i egen anläggning för uppvärmning av våra lokaler, isoleringen återvinns till lösull genom avtal med en lösullsentreprenör, plast och stålband sorteras för sig. Även kontor och lunchrumsavfall sorteras i så stor utsträckning som möjligt. Kvar blir det endast en liten del som blir deponeringsavfall, målet är dock 0% osorterat avfall.
                  </p>
               
              </div>
            </div>
          </div>
          <div class="col-md-6">
              <img src="../assets/img/kagerodl1.jpg" class="img-fluid" alt="...">      
          </div> 
        </div>
      </div>
  </section>    
    
  <!--====================================================
                        Kretsloppstänkande och återvinning
  ======================================================-->
  <section id="maskinhallar" class="business-growth-p1 ">
    <div class="container">
      
      <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
        <div class="col-md-6">
            <img src="../assets/img/blekhult_liared.jpg" class="img-fluid" alt="...">      
        </div> 
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h3>Kretsloppstänkande och återvinning </h3>
              <div class="heading-border-light"></div>
              <p>
                  De byggkomponenter som vi idag producerar och levererar byggs in i hus som skall stå i många år. Men en dag skall det ändå byggas om, renoveras eller rivas. Redan idag har vi på Timmele Takstolsfabrik omsorg om vad det skall bli av det material som då kasseras. Vi har asbesten i gott minne och vilka problem och kostnader det förde med sig. Den största delen av materialet i våra produkter är trä som går att återanvända eller tas omhand av naturen. Metallen i spikplåtar och beslag kan smältas ner till ny metall och mineralullen rives till lösull. Med prefabricerade komponenter från Timmele Takstolsfabrik blir byggplatsen ren från byggavfall som ofta blir deponeringsavfall. Vårt miljöarbete är inte slut i och med detta, det är bara en liten början på ett stort arbete.
              </p>
              
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>    
  
  <!--====================================================
                        lagkrav 
  ======================================================-->
  <section class="business-growth-p1  bg-gray" style="padding-bottom:3rem;padding-top:6rem;" >
    <div class="container">
      
      <div class="row" >
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h3>Ett lagkrav från 1 oktober 2010.</h3>
              <div class="heading-border-light"></div>
              <p>Slutbevis på byggnaden utfärdas endast om samtliga takstolar är märkta med CE, och ingen justering eller komplettering är gjord på byggplatsen. Detta gäller alla typer av byggnader.</p><p>
                  SP (Sveriges Tekniska Forskningsinstitut), ger företag rätt att använda CE-märket om de är beredda att säkerställa en hög kvalité på sina takstolar genom en löpande egenkontroll och genom en övervakande kontroll av en oberoende organisation, SP. Kvalitet i alla led. Den interna och övervakande kontrollen omfattar alla led i tillverkningsprocessen såsom:</p>
             
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <img src="../assets/img/Bildertakstolsfabriken/DSC_5519.jpg" class="img-fluid" alt="...">      
        </div> 
      </div>
      <div class="row ">            
          <div class="col-md-3 col-sm-6" >
            <div class="story-descb">
              
              <div class="cardbody">
                  <h3>Dimensionering</h3>
                  <div class="heading-border-light"></div>
                  <p>
                      Konstruktörerna på ett CE-märkt företag är licenserade för att konstruera takstolar. Ett väl utvecklat och godkännt dataprogram ger också en säkerhet och optimering i beräkningen av mer komplicerade takstolar.
                  </p>  
              </div>                  
            </div>          
          </div>        
          <div class="col-md-3 col-sm-6" >
              <div class="story-descb">
                        
                  <div class="cardbody">
                      <h3>Virkeskvalité</h3>
                      <div class="heading-border-light"></div> 
                      <p>
                          Krav ställs på hållfasthetsklass, dimensioner och fuktkvot. Hög precision i sågsnitt säkerställer god anliggning mellan virkesstycken i takstolen.
                      </p>  
                  </div>                  
                </div>          
            </div>      
          <div class="col-md-3 col-sm-6 "> 
              <div class="story-descb">
                         
                  <div class="cardbody">
                      <h3>Spikplåtar</h3>
                      <div class="heading-border-light"></div>
                      <p>
                          Spikplåtar ska vara typgodkända. Plåtens storlek, placering och inpressning är viktiga faktorer för förbandens hållfasthet.
                      </p>  
                  </div>                  
                </div>          
          </div>    
          <div class="col-md-3 col-sm-6 "> 
              <div class="story-descb">
                   
                  <div class="cardbody">
                      <h3>Tillverkning</h3>
                      <div class="heading-border-light"></div> 
                      <p>
                          Takstolen färdigställs i ändamålsenliga fixturer som säkerställer att huvudmått och tillpassning uppfyller ställda toleranskrav. Kontroll sker löpande i produktionen och resultaten journalförs.
                      </p>  
                  </div>                  
                </div>          
          </div>              
      </div> 
      <div class="row mt-4" >
          <div class="col-md-8">
            <div class="single-news-p1-cont" >
              
              <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                <h4>Forum för information och rådgivning.</h4>
                <div class="heading-border-light"></div>
                <p>En samrådsgrupp är inrättad och består av representanter för tillverkare av takstolar och spikplåtar, beräkningsbyråer, berörda myndigheter samt SP. Gruppen har till uppgift att
                  - ta till vara den kunskap som finns inom området
                  - verka för en effektiv erfarenhetsåterföring
                  - samt bidra till en samordnad information om trätakstolar
              </p>
               
              </div>
            </div>
          </div>
          <div class="col-md-4">
              <img src="../assets/img/kagerodl1.jpg" class="img-fluid" alt="...">      
          </div> 
        </div>
    </div>  
  </section>    
  
  <!--====================================================
                          CE Märkningsblock
  ======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1>CE</h1>
        </div>  
        <div class="col-md-8 ">
          <p >Vi på timmele takstolsfabrik tillverkar CE-märkta takstolar! CE märkning är ett krav från 1 oktober 2010.
            Certifikatnummer SC 0212-10</p>
        </div> 
      </div>
    </div>         
  </section> 
  
  <!--====================================================
                         Siddelare
  ======================================================-->
  <div id="foretaget" class="home-p pages-head1  text-center" >
      <div class="container" >
        <h1 class="wow fadeInUp" data-wow-delay="0.1s">Företaget</h1>
        <p>Takstolsfabriken i tiden!</p>
      </div><!--/end container-->
  </div> 
  
  <!--====================================================
                        Historia
  ======================================================-->
  <section  class="business-growth-p1 ">
      <div class="container">    
        <div class="row" style="padding-bottom:2rem;padding-top:6rem;">
          <div class="col-md-6">
            <div class="single-news-p1-cont" >          
              <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                <h2>Historien</h2>
                <div class="heading-border-light"></div>
                <p>
                  Låt oss göra en kort presentation av företaget och dess historia. Vi är, precis som namnet säger, ett företag som specialiserat oss på att tillverka takstolar för den svenska marknaden.
              </p><p>
                  För oss är inte takstolar en bisyssla, vilket innebär att vi alltid strävar efter att vara bäst i allt, från konstruktion och tekniska lösningar på komplicerade tak till att leverera rätt saker i rätt tid.
              </p><p>               
                 Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
                </p><p>Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
                </p>
                <p>Certifikatnummer SC 0212-10</p>            
              </div>
            </div>
          </div>
          <div class="col-md-6">  
              <p>
                  <img src="../assets/img/Bildertakstolsfabriken/DSC_5500.jpg" class="img-fluid" alt="..."> 
              </p>        
              <p>
                  <img src="../assets/img/Bildertakstolsfabriken/DSC_5566.jpg" class="img-fluid" alt="..."> 
              </p>            
              
          </div> 
        </div>
      </div>
  </section>    
  
  <!--====================================================
                        Företagets historia
  ======================================================-->
  <section id="maskinhallar" class="business-growth-p1 ">
      <div class="container">
        
        <div class="row" style="padding-bottom:2rem;padding-top:0rem;">
          <div class="col-md-6">
              <img src="../assets/img/Bildertakstolsfabriken/DSC_5559.jpg" class="img-fluid" alt="...">      
          </div> 
          <div class="col-md-6">
            <div class="single-news-p1-cont" >
              
              <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                <h3>Företagets historia börjar 1966 </h3>
                <div class="heading-border-light"></div>
                <p>
                  LBA (AB Lantbruksanläggningar) bildades 1966. LBA startade som ett montageföretag på lantbruket och ganska snart började man även att bygga kompletta lantbruksbyggnader. Att prefabricera lantbruksbyggnader var något nytt för den tiden, men det visade sig vara en utveckling åt rätt håll och 1968 byggdes den första delen av fabriken i Timmele för tillverkning av takstolar och väggelement för de egna entreprenaderna.
              </p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
  </section>  
    <!--====================================================
                        Historia
  ======================================================-->
  <section id="historia" class="business-growth-p1 ">
      <div class="container">    
        <div class="row" style="padding-bottom:6rem;padding-top:2rem;">
          <div class="col-md-6">
            <div class="single-news-p1-cont" >          
              <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                
                <p>
                  För oss är inte takstolar en bisyssla, vilket innebär att vi alltid strävar efter att vara bäst i allt, från konstruktion och tekniska lösningar på komplicerade tak till att leverera rätt saker i rätt tid.
              </p><p>               
                 Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
                </p><p>Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
                </p>
                <p>Certifikatnummer SC 0212-10</p>            
              </div>
            </div>
          </div>
          <div class="col-md-6">  
              <p>
                  <img src="../assets/img/Bildertakstolsfabriken/DSC_5599.jpg" class="img-fluid" alt="..."> 
              </p>                            
              
          </div> 
        </div>
        
      </div>
  </section> 
    
    