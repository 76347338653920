 <!--====================================================
                        FAQ-P1
  ======================================================-->
  <!-- <section id="faq-p12"  style="padding-top:2rem; padding-bottom:3rem;"> -->
    <div class="container">
      <div class="row pt-5 pb-5" >
        <div class="col-md-12">
          <h2>Vanliga frågor</h2>
          <div class="heading-border-light"></div>
          <div class="faq-p1-cont">
            <div class="service-h-tab"> 
              <nav class="nav nav-tabs" id="myTab" role="tablist">
                <!-- <a class="nav-item nav-link " id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-expanded="true">De 10 stegen</a>
                <a class="nav-item nav-link " id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile">Vanliga frågor</a>                    -->
              </nav>              
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade  show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">      
                  <div class="toggle" *ngFor="let itm of faqLista; let i = index" data-expand="false" #expandWrap>
                    <div class="toggle-title" (click)="expandWrap.dataset.expand = faqtoggle(expandWrap.dataset.expand)">                      
                      <h3>
                      <i></i>
                      <span class="title-name">{{i +1}}. {{itm.acf.rubrik}}</span>
                      </h3>
                    </div>
                    <div class="toggle-inner" *ngIf="expandWrap.dataset.expand == 'true'">
                      <img *ngIf="itm.acf.visa_bild" src="{{itm.acf.bild.url}}" class="img-fluid" alt="{{itm.acf.bild.alt}}" />
                      <p [innerHTML]="itm.acf.text"></p>
                      <a *ngIf="itm.acf.visa_lank" href="{{itm.acf.lank}}" alt="{{itm.acf.lanktext}}" title="{{itm.acf.lanktext}}">
                        {{itm.acf.lanktext}}
                      </a>
                    </div>
                  </div> 
                  
                </div>
                <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div class="toggle">
                    <div class="toggle-title ">
                      <h3>
                      <i></i>
                      <span class="title-name"> Vad händer om jag inte har alla uppgifter?</span>
                      </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name">Var hittar jag fraktkostnader?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Är det bara möjligt att välja takstol utifrån dom takstolstyper in har på hemsidan?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Vad gör jag om jag gjort en fel beställning?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Kan jag ändra i ritningarna efter att jag fått en offert?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Hur gör jag om det är något fel på leveransen?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  <!-- </section>   -->
  