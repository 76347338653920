 
<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">{{mainPageData[0].acf.toprubrik}}</h1>
      <h2 style="color: #fff;"><i class="fa fa-phone"></i> {{mainPageData[0].acf.toptelnr}} </h2>
       <p [innerHTML]="mainPageData[0].acf.toptext"></p>
    </div><!--/end container-->
  </div> 

<!--====================================================
                      CONTACT-P1  sälj
======================================================--> 
<app-salj></app-salj>

<!--====================================================
                      CONTACT-P1  Admin
======================================================--> 
<app-admin></app-admin>

<section id="comp-offer" class="team-p2">
<div class="container">
    <div class="row">
    <div class="col-12 ">
        <h2>Produktion</h2>
        <div class="heading-border-light"></div> 
    </div>
    </div>  
    <div class="row">  
    <div class="col-12 ">
        <div class="single-news-img text-center">        
          <img src="{{mainPageData[0].acf.personalbild?.url}}" class="img-fluid" alt="{{mainPageData[0].acf.personalbild?.alt}}" />
        </div>
    </div>
    </div>
</div>
</section>

<!--====================================================
                        CONTACT-P1  
======================================================--> 
<section id="contact-p1" class="contact-p1" *ngIf="mainPageData[0].acf.visakontaktform" style="background-color: rgb(240, 240, 240,.5); margin-left:0px; margin-right: 0px;" >
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <div class="contact-p1-cont">
          <h3>{{mainPageData[0].acf.kontaktrubrik}}</h3>
          <div class="heading-border-light"></div> 
          <div [innerHTML]="mainPageData[0].acf.kontakttext">
           </div>
        </div>
      </div>
      <div class="col-md-4"> 
        <div class="contact-p1-cont2"> 
          <h4>{{mainPageData[0].acf.adressrubrik}}</h4>
          <div class="heading-border-light"></div> 
          <address class="address-details-f" [innerHTML]="mainPageData[0].acf.adresstext">            
          </address>         
        </div>
      </div>  
    </div>
  </div>

  <app-kontakt-form></app-kontakt-form>
</section>


<!--====================================================
                     MAP
======================================================--> 
  <section id="contact-add" *ngIf="mainPageData[0].acf.visakarta">
    <div id="map">
      <div class="map-responsive">
         <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3569.813716955433!2d13.418313405195113!3d57.858624988194386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465a901a665bfb79%3A0xff8af3fd0e587fe!2sTimmele%20Takstolsfabrik!5e0!3m2!1ssv!2sse!4v1623188120686!5m2!1ssv!2sse" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div> 
  </section>