
<!--====================================================
                    LOGIN OR REGISTER
======================================================-->
    <!-- <section id="login">
      <div class="modal fade" id="login-modal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="display: none;">
          <div class="modal-dialog">
              <div class="modal-content">
                  <div class="modal-header" align="center">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span class="fa fa-times" aria-hidden="true"></span>
                      </button>
                  </div>
                  <div id="div-forms">
                      <form id="login-form">
                          <h3 class="text-center">Login</h3>
                          <div class="modal-body">
                              <label for="username">Username</label> 
                              <input id="login_username" class="form-control" type="text" placeholder="Enter username " required>
                              <label for="username">Password</label> 
                              <input id="login_password" class="form-control" type="password" placeholder="Enter password" required>
                              <div class="checkbox">
                                  <label>
                                      <input type="checkbox"> Remember me
                                  </label>
                              </div>
                          </div>
                          <div class="modal-footer text-center">
                              <div>
                                  <button type="submit" class="btn btn-general btn-white">Login</button>
                              </div>
                              <div>
                                  <button id="login_register_btn" type="button" class="btn btn-link">Register</button>
                              </div>
                          </div>
                      </form>
                      <form id="register-form" style="display:none;">
                          <h3 class="text-center">Register</h3>
                          <div class="modal-body"> 
                              <label for="username">Username</label> 
                              <input id="register_username" class="form-control" type="text" placeholder="Enter username" required>
                              <label for="register_email">E-mailId</label> 
                              <input id="register_email" class="form-control" type="text" placeholder="Enter eMail" required>
                              <label for="register_password">Password</label> 
                              <input id="register_password" class="form-control" type="password" placeholder="Password" required>
                          </div>
                          <div class="modal-footer">
                              <div>
                                  <button type="submit" class="btn btn-general btn-white">Register</button>
                              </div>
                              <div>
                                  <button id="register_login_btn" type="button" class="btn btn-link">Log In</button>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
    </section> -->

<!--====================================================
                       Offert
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Offert</h1>
      <p><a [routerLink]="[]" fragment="offert" class="smooth-scrolls">FRÅN FÖRFRÅGAN TILL LEVERANS</a></p>
    </div><!--/end container-->
  </div> 
  
  <!--====================================================
                        Begär offert
  ======================================================-->
  <section id="Takstolar" class="business-growth-p1">
    <div class="container">
      
      <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h2>Begär offert</h2>
              <div class="heading-border-light"></div>
              <p>Vi kontaktar dig och hjälper dig i din offertförfrågan <br> Du får hjälp från förfrågan till leveran så att du som kund kan känna dej trygg i din beställning</p>
              
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <img src="../assets/img/Bildertakstolsfabriken/DSC_5571.jpg" class="img-fluid" alt="...">      
        </div> 
      </div>
    </div>
  </section>  
  
  <!--====================================================
                          Offert formulär  
  ======================================================--> 
  <section id="offert" class=" bg-gray" style="padding-bottom:3rem;padding-top:3rem; margin-left:0px; margin-right: 0px;" >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="contact-p1-cont">
            <h2>Skicka in din offertförfrågan</h2>
            <div class="heading-border-light"></div>          
          </div>
        </div>      
          
      </div>
    </div>
    <div class="container" >
        <div class="row con-form">
          
          <div class="col-12 col-md-8">         
              <label></label>
              <input type="text" name="full-name" placeholder="Namn" class="form-control">
              <label></label>
              <input type="text" name="full-name" placeholder="Företag" class="form-control">
              <label></label>
              <input type="text" name="email" placeholder="E-post" class="form-control">          
          </div>
          <div class="col-md-12">          
            <textarea name="" id="" placeholder="Underlag för offertförfrågan" class="form-control"></textarea>
          </div>
  
          <div class="col-12 col-md-8">   
            <div class="panel panel-default ">
              <div class="input-group image-preview">
                <input placeholder="" type="text" class="form-control image-preview-filename" disabled="disabled">
                <span class="input-group-btn"> 
                  <button type="button" class="btn btn-default image-preview-clear" style="display:none;"> 
                    <span class="glyphicon glyphicon-remove"></span> Clear 
                  </button>
                  <div class="btn btn-default image-preview-input"> 
                    <span class="glyphicon glyphicon-folder-open"></span> 
                    <span class="image-preview-input-title">Ladda upp underlag</span>
                    <input type="file" accept="image/png, image/jpeg, image/gif" name="input-file-preview" />
                  </div> 
                </span>
              </div>
            </div>
          </div>
  
          <div class="col-md-12 sub-but">          
            <button class="btn btn-general btn-white mt-4" role="button">Skicka in </button>
          </div>
  
        </div>
    </div>
  </section>
  
  
  <!--====================================================
                        FAQ-P1
  ======================================================-->
  <section id="faq-p12"  style="padding-top:2rem; padding-bottom:3rem;">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2>Vanliga frågor</h2>
          <div class="heading-border-light"></div>
          <div class="faq-p1-cont">
            <div class="service-h-tab"> 
              <nav class="nav nav-tabs" id="myTab" role="tablist">
                <!-- <a class="nav-item nav-link " id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-expanded="true">De 10 stegen</a> -->
                <!-- <a class="nav-item nav-link " id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile">Vanliga frågor</a>                    -->
              </nav>
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">      
                  <div class="toggle">
                    <div class="toggle-title ">
                      <h3>
                      <i></i>
                      <span class="title-name">1. Ritningar och takstolstyper</span>
                      </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>
                        Skicka över samtliga handlingar som finns på byggnaden till oss via post, mail eller fax. Saknar Ni handlingar går det bra att skriva ut lämplig typ av takstol från Takstolstyper      <br>  Glöm inte namn, adress, mailadres, telefonnummer samt fastighetsbeteckning.</p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">2. Presentation av förslag</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                        <p>När vi är överens om hur taket skall se ut och vilken funktion det skall ha presenterar vi färdiga ritningar tillsammans med ett pris.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">3. Genomgång av taklösning</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                        <p>Om det finns oklarheter går vi tillsammans igenom taklösningen med Er.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">4. Bygghandling och avtal</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                        <p>Vid beställning gör vi en sista genomgång och efter det skickar vi över ritningar märkta "Bygghandling" samt avtal för underskrift.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">5. Leveransöverenskommelse</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                        <p>Vid beställning kommer vi även överens om en leveransvecka. I vissa regioner levererar vi med kranbil som även kan lyfta takstolarna på plats.
                        <br> Detta ingår inte i fraktkostnaden, utan debiteras extra och kräver viss planering.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">6. Detta är steg sex </span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Vid beställning kommer vi även överens om en leveransvecka. I vissa regioner levererar vi med kranbil som även kan lyfta takstolarna på plats.
                        <br> Detta ingår inte i fraktkostnaden, utan debiteras extra och kräver viss planering.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">7. Detta är steg sju</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Vid beställning kommer vi även överens om en leveransvecka. I vissa regioner levererar vi med kranbil som även kan lyfta takstolarna på plats.
                        <br> Detta ingår inte i fraktkostnaden, utan debiteras extra och kräver viss planering.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">8. Detta är steg åtta</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Vid beställning kommer vi även överens om en leveransvecka. I vissa regioner levererar vi med kranbil som även kan lyfta takstolarna på plats.
                        <br> Detta ingår inte i fraktkostnaden, utan debiteras extra och kräver viss planering.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                        <h3>
                        <i></i>
                        <span class="title-name">9. Detta är steg nio</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Vid beställning kommer vi även överens om en leveransvecka. I vissa regioner levererar vi med kranbil som även kan lyfta takstolarna på plats.
                        <br> Detta ingår inte i fraktkostnaden, utan debiteras extra och kräver viss planering.
                        </p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title ">
                        <h3>
                        <i></i>
                        <span class="title-name">10. Detta är steg tio</span>
                        </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Vid beställning kommer vi även överens om en leveransvecka. I vissa regioner levererar vi med kranbil som även kan lyfta takstolarna på plats.
                        <br> Detta ingår inte i fraktkostnaden, utan debiteras extra och kräver viss planering.
                        </p>
                    </div>
                  </div> 
                </div>
                <div class="tab-pane fade  show active" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  <div class="toggle">
                    <div class="toggle-title ">
                      <h3>
                      <i></i>
                      <span class="title-name"> Vad händer om jag inte har alla uppgifter?</span>
                      </h3>
                    </div>
                    <div class="toggle-inner">
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                    </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name">Var hittar jag fraktkostnader?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Är det bara möjligt att välja takstol utifrån dom takstolstyper in har på hemsidan?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Vad gör jag om jag gjort en fel beställning?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Kan jag ändra i ritningarna efter att jag fått en offert?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                  <div class="toggle">
                    <div class="toggle-title  ">
                      <h3>
                        <i></i>
                        <span class="title-name"> Hur gör jag om det är något fel på leveransen?</span>
                        </h3>
                      </div>
                      <div class="toggle-inner">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vestibulum velit id urna imperdiet, et malesuada quam accumsan. Integer malesuada ac nibh ac elementum. Donec pulvinar ac erat id fringilla. Morbi accumsan mauris ligula. Vivamus sit amet magna semper, tincidunt risus nec, tempus tellus. Pellentesque a erat et velit laoreet aliquam. Sed convallis ligula eu mi egestas porttitor id a urna. Cras non maximus tortor.</p>
                      </div>
                  </div> 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>      
  </section>  
  
  
  <section class="al-cta-flat bg-parallax contact-h-bg">
    <div  class="container">
        <div class="row" style="padding-bottom:6rem;padding-top:3rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Har du fler Frågor?</h2>
                    <p class="mt-3" style="color:black; font-size: 1.4rem;">Är det något du undrar över så tveka inte att ta kontakt med oss</p>
                    <a routerLink="kontakt" class="btn btn-info btn-lg py-2 px-5 mt-4 font-weight-bold">Kontakta oss!!! </a>
                </div>
            </div>
  
        </div>
    </div>
  </section>
  