 
<!--====================================================
                       HOME-P
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Takstolar</h1>
      <p>Välj ur vårt sortiment av takstolstyper </p>
    </div><!--/end container-->
  </div> 
  <div class="container">
    <div class="row title-bar">
      <div class="col-md-12">          
        <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.</p>
        <p>Certifikatnummer SC 0212-10</p>
      </div>
    </div>
  </div>
<!--====================================================
                      news-p1
======================================================--> 
  
    <div class="container">
      <div class="row">
       
       
        <div class="col-md-12 text-center">
          <h3>Välj den typ av takstol som bäst passar din konstruktion</h3>
        </div>
     
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.1s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb">
              <h4>Fackverk</h4>
              <img src="../assets/img/takstolar/takstol_fackverk.jpg" class="img-fluid" alt="...">              
              <p></p>
              <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Gör beräkning och offertförfrågan för takstolstyp: fackverk</a> 
            </div>
          </a>
        </div>
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.3s"> 
          <a href="takstolsTypForm3.html">  
            <div class="story-descb">
              <h4>Ramverk</h4>
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">              
              <p></p>
              <a href="takstolsTypForm3.html"><i class="fa fa-arrow-circle-o-right"></i> Gör beräkning och offertförfrågan för takstolstyp: Ramverk </a>
            </div>
          </a>
        </div>
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.5s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb">
              <h4>Fackverk med tass</h4>
              <img src="../assets/img/takstolar/takstol_fackverk_med_tass.jpg" class="img-fluid" alt="...">              
              <p></p>
              <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Gör beräkning och offertförfrågan för takstolstyp: fackverk med tass </a>
            </div>
          </a>
        </div>    
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.1s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb">
              <h4>Pulpet</h4>
              <img src="../assets/img/takstolar/takstol_pulpet.jpg" class="img-fluid" alt="...">              
              <p>Ange spännvidd, vinklar, mm. och gör en offertförfrågan</p>
              <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Beräkning och offertförfrågan för pulpet </a>
            </div>
          </a>
        </div>                    
      
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.1s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb"> 
                <h4>Mansard</h4>
                <img src="../assets/img/takstolar/takstol_masard.jpg" class="img-fluid" alt="...">               
                <p>Ange spännvidd, vinklar, mm. och gör en offertförfrågan</p>
                <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Beräkning och offertförfrågan för mansard</a>
            </div>
          </a>
        </div>
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.3s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb">
              <h4>Ramverk</h4>
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">              
              <p>Ange spännvidd, vinklar, mm. och gör en offertförfrågan</p>
              <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Beräkning och offertförfrågan för Ramverk </a>
            </div>
          </a>
        </div>
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.5s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb">
              <h4>Fackverk med tass</h4>
              <img src="../assets/img/takstolar/takstol_fackverk_med_tass.jpg" class="img-fluid" alt="...">              
              <p>Ange spännvidd, vinklar, mm. och gör en offertförfrågan</p>
              <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Beräkning och offertförfrågan för fackverk med tass </a>
            </div>
          </a>
        </div>    
        <div class="col-md-4 col-sm-6 " data-wow-delay="0.1s"> 
          <a href="takstolsTypForm.html">
            <div class="story-descb">
              <h4>Pulpet</h4>
              <img src="../assets/img/takstolar/takstol_pulpet.jpg" class="img-fluid" alt="...">              
              <p>Ange spännvidd, vinklar, mm. och gör en offertförfrågan</p>
              <a href="takstolsTypForm.html"><i class="fa fa-arrow-circle-o-right"></i> Beräkning och offertförfrågan för pulpet </a>
            </div>
          </a>
        </div>                                   
      </div>
    </div>  
