<!--====================================================
                       Vår Tillverkning
======================================================-->
<div id="home-p" class="home-p pages-head1 text-center">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Vår Tillverkning</h1>
      <p><a [routerLink]="[]" fragment="Takstolar" class="smooth-scrolls">Takstolar</a> - <a [routerLink]="[]" fragment="maskinhallar" class="smooth-scrolls">maskinhallar</a> - <a [routerLink]="[]" fragment="husstommar" class="smooth-scrolls">husstommar</a>- <a [routerLink]="[]" fragment="posijoints" class="smooth-scrolls">Posi-Joints</a></p>
    </div><!--/end container-->
  </div> 
  
  
  <!--====================================================
                        Takstolar -block
  ======================================================-->
  <section id="Takstolar" class="business-growth-p1 ">
    <div class="container">
      
      <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h2>Takstolar</h2>
              <div class="heading-border-light"></div> 
              <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. 
                </p><p>Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
              </p>
              <p>Certifikatnummer SC 0212-10</p>
              
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <img src="../assets/img/Bildertakstolsfabriken/DSC_5531.jpg" class="img-fluid" alt="...">      
        </div> 
      </div>
    </div>
  </section>    
     
  <section id="business-growth-p1" class="business-growth-p1 bg-gray"style="padding-bottom:3rem;padding-top:3rem;">
    <div class="container">
      <div class="single-news-desc m-0 pt-1 pb-0 px-0" >
        <h3>Vanliga takstolstyper</h3>    
        <p>Vi skräddarsyr dina Takstolar. Tillsammans med kund tar vi fram och konstruerar takstolar efter kundens önskemål... Vi utgår ifrån dessa vanliga takstolstyper och ser till att tt säkerställa kvalité på både konstruktion och produkten.
        </p>      
        <hr> 
      </div>
      
      <div class="row wow ">
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
              <img src="../assets/img/takstolar/takstol_masard.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
              <img src="../assets/img/takstolar/takstol_pulpet.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item right-bord">
              <img src="../assets/img/takstolar/takstol_fackverk.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div> 
      
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item">
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div>
        <div class="col-md-3 col-sm-6 service-padding">
            <div class="service-item right-bord">
              <img src="../assets/img/takstolar/takstol_ramverk.jpg" class="img-fluid" alt="...">    
              <h5>Vacation</h5>
            </div>
        </div> 
      </div>
    </div>     
  </section>
  
  <section class="al-cta-flat">
    <div class="container">
        <div class="row" style="padding-bottom:3rem;padding-top:3rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Kontakta oss direkt</h2>
                    <p class="mt-3">Kontakta oss och begär offert så tillverkar vi en takstol som passar dina önskemål </p>
                    <a href="#" class="btn btn-info btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Kontakta oss! </a>
                    <a href="#" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                </div>
            </div>
  
        </div>
    </div>
  </section>
  
  <!--====================================================
                          CE märkning block
  ======================================================-->
  <div class="overlay-career-p2"></div>
  <section id="thought" class="bg-parallax career-p2-bg">
    <div class="container">
      <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
          <h1>CE</h1>
        </div>  
        <div class="col-md-8 ">
          <p >Vi på timmele takstolsfabrik tillverkar CE-märkta takstolar! CE märkning är ett krav från 1 oktober 2010.
            Certifikatnummer SC 0212-10</p>
        </div> 
      </div>
    </div>         
  </section> 
  
  <!--====================================================
                         Övrig tillverkning delare
  ======================================================-->
  <div class="home-p pages-head1  text-center" >
    <div class="container" >
      <h1 class="wow fadeInUp" data-wow-delay="0.1s">Övrig tillverkning</h1>
      <p style="display: none;">Vi tillverkar för att tillgodose ditt behov!</p>
    </div><!--/end container-->
  </div> 
  
  <!--====================================================
                        Maskinhallar
  ======================================================-->
  <section id="maskinhallar" class="business-growth-p1 ">
    <div class="container">
      
      <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
        <div class="col-md-6">
            <img src="../assets/img/blekhult_liared.jpg" class="img-fluid" alt="...">      
        </div> 
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h2>Maskinhallar</h2>
              <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
              </p>
                <p>Certifikatnummer SC 0212-10</p>
              <p>Fyll i alla uppgifter för offertförfrågan</p>
              <hr> 
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>    
  
  <section class="al-cta-flat">
    <div class="container">
        <div class="row" style="padding-bottom:3rem;padding-top:0rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Begär offert på maskinhallar</h2>
                    <p class="mt-3">Kontakta oss och begär offert så tillverkar vi en takstol som passar dina önskemål </p>
                    <a href="#" class="btn btn-info btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Kontakta oss! </a>
                    <a href="#" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                </div>
            </div>
  
        </div>
    </div>
  </section>
  
  <!--====================================================
                        Husstommar
  ======================================================-->
  <section id="husstommar" class="business-growth-p1  bg-gray">
    <div class="container">
      
      <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h2>Husstommar</h2>
              <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
              </p>
                <p>Certifikatnummer SC 0212-10</p>
              <p>Fyll i alla uppgifter för offertförfrågan</p>
              <hr> 
            </div>
          </div>
        </div>
        <div class="col-md-6">
            <img src="../assets/img/kagerodl1.jpg" class="img-fluid" alt="...">      
        </div> 
      </div>
    </div>
  </section>    
  
  <section class="al-cta-flat bg-gray">
    <div class="container">
        <div class="row" style="padding-bottom:3rem;padding-top:0rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Begär offert på husstommar</h2>
                    <p class="mt-3">Kontakta oss och begär offert så tillverkar vi en takstol som passar dina önskemål </p>
                    <a href="#" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                </div>
            </div>
  
        </div>
    </div>
  </section>
  
  <!--====================================================
                        BUSINESS-GROWTH-P1
  ======================================================-->
  <section id="business-growth-p1" class="business-growth-p1 " style="display: none;">
    <div class="container">
      <div class="row single-news-p1-cont">
        <div class="col-md-6 ">
          <h2 class="wow fadeInUp">Maskinhallar</h2>
          <div class="heading-border2"></div>
          <div class="service-item">
            <img src="../assets/img/blekhult_liared.jpg" class="img-fluid" alt="...">    
           
          </div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">We committed to helping you maintain your oral healthTooth.We are an innovative company. We develop and design websites for costumers around the world. Our clients are some of the most forward-looking companies in the world.</p>
          <div class="container">
            <div class="row" style="padding-bottom:3rem;">
                <div class="col-sm-12">
                    <div class="al-cta-box text-center">                                         
                        <a href="#" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                    </div>
                </div>
      
            </div>
          </div>
        </div>      
    
        <div class="col-md-6">
          <h2 class="wow fadeInUp">Husstommar</h2>
          <div class="heading-border2"></div>
          
          <div class="service-item">
            <img src="../assets/img/kagerodl1.jpg" class="img-fluid" alt="...">             
          </div>
          <p class="wow fadeInUp" data-wow-delay="0.4s">We committed to helping you maintain your oral healthTooth.We are an innovative company. We develop and design websites for costumers around the world. Our clients are some of the most forward-looking companies in the world.</p>
          <div class="container">
            <div class="row" style="padding-bottom:3rem;">
                <div class="col-sm-12">
                    <div class="al-cta-box text-center">                                      
                        <a href="#" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                    </div>
                </div>
      
            </div>
        </div>
        </div>      
  
        <!-- <div class="col-md-4">
          
          <div class="heading-border2"></div>
          <div class="service-item">
            <img src="../assets/img/fraktkrok.jpg" class="img-fluid" alt="...">             
          </div><h2 class="wow fadeInUp">Frakt-krok</h2>
          <p class="wow fadeInUp" data-wow-delay="0.4s">Frakt – Lossning - Kranlyft – Övriga leveransvillkor Frakt:
            Framkomlig väg och vändmöjligheter för 24 meter lastbil med släp eller trailer.
            <br>
            
            Montera dina takstolar med CE godkänd lyftutrustning:</p>
          
        </div>       -->
      </div>
    </div>  
  </section>     
  
  <!--====================================================
                        Posi-Joints
  ======================================================-->
  <section id="posijoints" class="business-growth-p1 ">
    <div class="container">
      
      <div class="row" style="padding-bottom:3rem;padding-top:6rem;">
        <div class="col-md-6">
            <img src="../assets/img/blekhult_liared.jpg" class="img-fluid" alt="...">      
        </div> 
        <div class="col-md-6">
          <div class="single-news-p1-cont" >
            
            <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
              <h2>Posi-Joints</h2>
              <p>Vi tar ett helhetsansvar för ert tak, balkar, avväxlingar, kortlingar och givetvis att alla takstolar är med i leveransen och inget skall justerkapas på plats. Ett avtal med SP (Sveriges Tekniska Forskningsinstitut) ger oss rätt att CE-märka våra takstolar, detta för att säkerställa kvalité på både konstruktion och produkten. CE märkning är ett krav från 1 oktober 2010.
              </p>
                <p>Certifikatnummer SC 0212-10</p>
              <p>Fyll i alla uppgifter för offertförfrågan</p>
              <hr> 
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </section>    
  
  <section class="al-cta-flat">
    <div class="container">
        <div class="row" style="padding-bottom:3rem;padding-top:0rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Begär offert på maskinhallar</h2>
                    <p class="mt-3">Kontakta oss och begär offert så tillverkar vi en takstol som passar dina önskemål </p>
                    <a href="#" class="btn btn-info btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Kontakta oss! </a>
                    <a href="#" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                </div>
            </div>
  
        </div>
    </div>
  </section>