<!--====================================================
                       Top block
======================================================-->
<div id="blockid_{{blockid}}" class="home-p pages-head1 text-center" *ngIf="valPageblock.topblockval">
    <div class="container">
      <h1 class="wow fadeInUp" data-wow-delay="0.1s" [innerHtml]="valPageblock.toprubrik"></h1>
      <p [innerHtml]="valPageblock.topunderrubrik"></p>
    </div><!--/end container-->
</div> 

<!--====================================================
                    Bild till vänster block
======================================================-->
<section class="business-growth-p1" [ngClass]="stylehandler(valPageblock.style_main)" *ngIf="valPageblock.visa_mainblock">
    
    <!--====================================================
                    Bild till vänster block
    ======================================================-->
    <div class="container" *ngIf="valPageblock.textleft">        
        <div class="row" [ngClass]="valPageblock.rubrik ? 'pb-3 padt-6' : 'pb-0 pt-0'" >
            
            <div class="col-md-6">
                <div class="single-news-p1-cont" >
                    <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                        <h2 id="rub_{{valPageblock.rubrik}}" [innerHtml]="valPageblock.rubrik"></h2>
                        
                        <div class="heading-border-light" *ngIf="valPageblock.rubrik" ></div>
                        
                        <div [innerHtml]="valPageblock.content"></div>
                        
                        <i class="fa fa-spinner fa-spin fa-3x fa-fw" *ngIf="!valPageblock.rubrik && !valPageblock.content"></i>
                        <span class="sr-only">Laddar ...</span>
                    </div>
                </div>
            </div>
            <div class="col-md-6">                  
                <img *ngIf="valPageblock.bild1.url" src="{{valPageblock.bild1.url}}" alt="{{valPageblock.bild1.alt}}" title="{{valPageblock.bild1.alt}}" loading="lazy" class="img-fluid">                  
                <img *ngIf="valPageblock.bild2.url" src="{{valPageblock.bild2.url}}" alt="{{valPageblock.bild2.alt}}" title="{{valPageblock.bild1.alt}}" loading="lazy" class="img-fluid mt-4">        
            </div> 
        </div>
    </div>

    <!--====================================================
                    Bild till höger Block
    ======================================================-->
    <div class="container" *ngIf="!valPageblock.textleft">
        <div class="row" [ngClass]="valPageblock.rubrik ? 'pb-3 padt-6' : 'pb-0 pt-0'">
            <div class="col-md-6">
                <img *ngIf="valPageblock.bild1.url" src="{{valPageblock.bild1.url}}" alt="{{valPageblock.bild1.alt}}" title="{{valPageblock.bild1.alt}}" loading="lazy" class="img-fluid ">                  
                <img *ngIf="valPageblock.bild2.url" src="{{valPageblock.bild2.url}}" alt="{{valPageblock.bild2.alt}}" title="{{valPageblock.bild1.alt}}" loading="lazy" class="img-fluid mt-4">                     
            </div> 
            <div class="col-md-6">
                <div class="single-news-p1-cont" >
                    <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                        <h2 id="rub_{{valPageblock.rubrik}}" [innerHtml]="valPageblock.rubrik"></h2>
                        <div class="heading-border-light" *ngIf="valPageblock.rubrik"></div>                            
                        <div [innerHtml]="valPageblock.content"></div>
                        
                        <i class="fa fa-spinner fa-spin fa-3x fa-fw" *ngIf="!valPageblock.rubrik && !valPageblock.content"></i>
                        <span class="sr-only">Laddar ...</span>

                    </div>
                </div>
            </div>        
        </div>
    </div>
</section>   

<!--====================================================
    List typ 1   Listning 4 Listar endast bilder
======================================================-->   
<section *ngIf="valPageblock.bildblockval" [ngClass]="stylehandler(valPageblock.style_4_bilder_block)" >        
    <app-listblock [listtyp]="1" [requestedList]="valPageblock.visa_4_bilder_fran_lista" ></app-listblock>    
</section>

<!--====================================================
    List typ 2    Listning 3 collumns items
======================================================-->    
<section *ngIf="valPageblock.bildblock3val" [ngClass]="stylehandler(valPageblock.style_3_bilder_block)">    
    <app-listblock [listtyp]="2" [requestedList]="valPageblock.visa_3_bilder_fran_lista" ></app-listblock>   
</section>

<!--====================================================
    List typ 3  Listning med rubrik och text ingen bild
======================================================-->   
<section *ngIf="valPageblock.textblock4val" class="business-growth-p1" [ngClass]="stylehandler(valPageblock.style_4_text_block)">
    <app-listblock [listtyp]="3" [requestedList]="valPageblock.visa_4_text_fran_lista" ></app-listblock>   
</section>

<!--====================================================
    List typ 4    Listning 4columners bilder med rubrik 
            antal kan var hur många som helst
======================================================-->       
<section *ngIf="valPageblock.listblock" id="business-growth-p1" class="business-growth-p1 pt-3 pb-3" [ngClass]="stylehandler(valPageblock.style_list_block)" >
    <app-listblock [listtyp]="4" [requestedList]="valPageblock.visa_listblockslista" ></app-listblock>     
</section>

<!--====================================================
            Visa  Offert Formulär            
======================================================-->       
<section *ngIf="valPageblock.visaoffertformular" id="offert" class="bg-gray pt-3 pb-3" [ngClass]="stylehandler(valPageblock.style_list_block)" >
    <app-offert-form></app-offert-form>       
</section>

<!--====================================================
            Listning FAQ 
            antal kan var hur många som helst
======================================================-->       
<section  *ngIf="valPageblock.visa_faq" >
    <app-faqblock></app-faqblock>       
</section>

<section class="al-cta-flat bg-parallax contact-h-bg"  *ngIf="valPageblock.visa_faq">
    <div  class="container">
        <div class="row" style="padding-bottom:6rem;padding-top:3rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Har du fler Frågor?</h2>
                    <p class="mt-3" style="color:black; font-size: 1.4rem;">Är det något du undrar över så tveka inte att ta kontakt med oss</p>
                    <a routerLink="/kontakt" class="btn btn-info btn-lg py-2 px-5 mt-4 font-weight-bold">Kontakta oss! </a>
                </div>
            </div>
  
        </div>
    </div>
  </section>
<!--====================================================
                        Mer infoblock
======================================================-->
<section *ngIf="valPageblock.merinfoblock" class="pt-3 pb-3" [ngClass]="stylehandler(valPageblock.style_merinfoblock)">
    <div class="container">
        <div class="row pt-5 pb-5" >
            <div class="col-md-8">
              <div class="single-news-p1-cont" >                
                <div class="single-news-desc m-0 pt-1 pb-0 px-0" style="font-size: 2.2rem;">
                  <h4>{{valPageblock.merinfo_rubrik}}</h4>
                  <div class="heading-border-light"></div>
                  <p [innerHTML]="valPageblock.merinfo_text"> </p>                 
                </div>
              </div>
            </div>
            <div class="col-md-4">
                <img src="{{valPageblock.merinfo_bild.url}}" alt="{{valPageblock.merinfo_bild.alt}}" title="{{valPageblock.merinfo_bild.alt}}" class="img-fluid" alt="...">      
            </div> 
        </div>
    </div>  
</section>    

<!--====================================================
                    Kontakta/ offert block
======================================================-->
<section class="al-cta-flat" *ngIf="valPageblock.kontaktblock" [ngClass]="stylehandler(valPageblock.style_main)">
    <div class="container">
        <div class="row" style="padding-bottom:3rem;padding-top:3rem;">
            <div class="col-sm-12">
                <div class="al-cta-box text-center">
                    <h2>Kontakta oss direkt</h2>
                    <p class="mt-3">Kontakta oss och begär offert så tillverkar vi en takstol som passar dina önskemål </p>
                    <a *ngIf="valPageblock.kontaktlank" routerLink="/kontakt" class="btn btn-info btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Kontakta oss! </a>
                    <a *ngIf="valPageblock.offertlank" routerLink="/offert" class="btn btn-success btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold">Begär offert! </a>
                </div>
            </div>  
        </div>
    </div>
</section>
  
<!--====================================================
                          CE Märkningsblock
======================================================-->
<div class="overlay-career-p2" *ngIf="valPageblock.ce_block"></div>
<section id="thought" class="bg-parallax career-p2-bg mb-0" *ngIf="valPageblock.ce_block" >
    <div class="container">
        <div id="row" class="row title-bar-career-p2">
        <div class="col-md-4 ">
            <h1>CE</h1>
        </div>  
        <div class="col-md-8 ">
            <p >Vi på timmele takstolsfabrik tillverkar CE-märkta takstolar! CE märkning är ett krav från 1 oktober 2010.
            Certifikatnummer SC 0212-10</p>
        </div> 
        </div>
    </div>         
</section> 
 <div class="pt-4 pb-4" [ngClass]="stylehandler(valPageblock.style_main)" *ngIf="!valPageblock.ce_block"></div> 