<!--====================================================
                         HOME
======================================================-->

<section id="home" class=" mt-0" style="background-color: #ccc;" >
  <div id="carousel" class="carousel slide carousel-fade" data-ride="carousel">
    <!-- Carousel items -->
    <div class="carousel-inner">
        <div class="carousel-item active slides">
          <div class="overlay"></div>
          <div class="slide-1"></div>
            <div *ngIf="mainPageData[0].acf.toprubrik" class="hero ">
              <hgroup class="wow fadeInUp">
                  <h1>{{mainPageData[0].acf.toprubrik}}</h1>
                  <h3>{{mainPageData[0].acf.topunderrubrik}}</h3>
              </hgroup>
              <button *ngIf="mainPageData[0].acf.topknapptext" class="btn btn-general btn-white_box wow fadeInUp" title="{{mainPageData[0].acf.topknapptext}}" (click)="goto(mainPageData[0].acf.toplink)" role="button">{{mainPageData[0].acf.topknapptext}}</button>
            </div>
        </div>
    </div>
  </div>
</section>

<!--====================================================
                    Aktuellt
======================================================-->
<div class="overlay-career-p2"></div>
<section id="thought" class="bg-parallax career-p2-bg pt-4 pb-4 mb-4 bg-chathams">
<div class="container">
<div id="row" class="row title-bar-career-p2">
  <div class="col-md-2 ml-md-auto"><h4>Aktuellt</h4>
    <div class="heading-border-light heading-border-light_white"></div>
  </div>
  <div class="col-md-10 ">

    <app-lista-nyheter></app-lista-nyheter>

  </div>
  <div class="col-md-3 text-center" style="display:none;">
    <button routerLink="/kontakt" title="Vill du veta mer?" class="btn btn-general btn-green" role="button">Vill du veta mer?</button>
  </div>
</div>
</div>
</section>

<section id="tillverkning " class="pb-5">
<div class="container">
<div class="row title-bar">
  <div class="col-md-12 pb-2 mb-2">
    <h2 class="wow fadeInUp ">Produkter</h2>
    <div class="heading-border"></div>
  </div>
</div>
  <div class="row ">
  <!-- <div class="col-md-12 col-sm-12 d-xl-none" >
    <div class="story-descb" (click)="goto(mainPageData[0].acf.startimgblockmainlinkurl)">
        <img src="../assets/img/takstolstillverkning2-2.jpg" class="img-fluid" alt="...">
        <div class="cardbody">
          <h2>{{mainPageData[0].acf.startimgblockmainrublik}}</h2>
          <div class="heading-border-light"></div>
          <p [innerHtml]="mainPageData[0].acf.startimgblockmaintext"> </p>
          <a routerLink="{{mainPageData[0].acf.startimgblockmainlinkurl}}" title="{{mainPageData[0].acf.startimgblockmainlinktext}}"><i class="fa fa-arrow-circle-o-right"></i> {{mainPageData[0].acf.startimgblockmainlinktext}}</a>
        </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12 wow fadeInUp d-none d-xl-block" data-wow-delay="0.1s">
    <a class="story-descb-start" (click)="goto(mainPageData[0].acf.startimgblockmainlinkurl)" title="{{mainPageData[0].acf.startimgblockmainlinktext}}">
        <div class="cardbody-start">
          <h2>{{mainPageData[0].acf.startimgblockmainrublik}}</h2>
          <div class="heading-border-light"></div>
          <p [innerHtml]="mainPageData[0].acf.startimgblockmaintext"> </p>
          <a routerLink="{{mainPageData[0].acf.startimgblockmainlinkurl}}" title="{{mainPageData[0].acf.startimgblockmainlinktext}}"><i class="fa fa-arrow-circle-o-right"></i> {{mainPageData[0].acf.startimgblockmainlinktext}}</a>
        </div>
    </a>
  </div> -->

<div class="col-md-6" *ngFor="let itm of mainPageData[0].acf.listastarttopimgblock let i = index">
  <a routerLink="{{itm.acf.lank}}" title="{{itm.acf.lanktext}}" fragment="{{itm.acf.anchor}}">
  <div class="story-descb">
        <img src="{{itm.acf.bild.url}}" class="img-fluid" alt="...">
        <div class="cardbody">
        <h3>{{itm.acf.rubrik}}</h3>
        <div class="heading-border-light"></div>
        <p [innerHTML]="itm.acf.text"></p>
        <a routerLink="{{itm.acf.lank}}" title="{{itm.acf.lanktext}}" fragment="{{itm.acf.anchor}}" *ngIf="itm.acf.visa_lank"><i class="fa fa-arrow-circle-o-right"></i> {{itm.acf.lanktext}}</a>
        </div>
    </div>
  </a>
</div>


  <div class="col-md-6 wow fadeInUp mt-4" data-wow-delay="0.1s" *ngFor="let itm of mainPageData[0].acf.listastartimgblock let i = index">
    <a routerLink="{{itm.acf.lank}}" title="{{itm.acf.lanktext}}" fragment="{{itm.acf.anchor}}" *ngIf="itm.acf.visa_lank">
    <div class="story-descb">
        <img src="{{itm.acf.bild.url}}" class="img-fluid" alt="...">
        <div class="cardbody">
        <h3>{{itm.acf.rubrik}}</h3>
        <div class="heading-border-light"></div>
        <p [innerHTML]="itm.acf.text"></p>
        <a routerLink="{{itm.acf.lank}}" title="{{itm.acf.lanktext}}" fragment="{{itm.acf.anchor}}" *ngIf="itm.acf.visa_lank"><i class="fa fa-arrow-circle-o-right"></i> {{itm.acf.lanktext}}</a>
        </div>
    </div>
  </a>
  </div>

</div>
</div>
</section>

<section class="al-cta-flat bg-gray mt-5" *ngIf="mainPageData[0].acf.visa_kontaktblock">
<div class="container">
  <div class="row" style="padding-bottom:3rem;padding-top:3rem;">
      <div class="col-sm-12">
          <div class="al-cta-box text-center">
              <h2>{{mainPageData[0].acf.kontaktblockrubrik}}</h2>
              <p class="mt-3">{{mainPageData[0].acf.kontaktblocktext}}</p>
              <a routerLink="/kontakt" title="Kontakta oss!" class="btn btn-info btn-lg py-2 px-5 mt-4 ml-1 mr-1 font-weight-bold" *ngIf="mainPageData[0].acf.visakontaktaossknapp">Kontakta oss! </a>
              <a routerLink="/offert" title="Begär offert!" class="btn btn-success btn-lg py-2 px-5 mt-4  ml-1 mr-1 font-weight-bold" *ngIf="mainPageData[0].acf.visaoffertknapp">Begär offert! </a>
          </div>
      </div>

  </div>
</div>
</section>
<!--====================================================
                    Företaget
======================================================-->
<section id="comp-offers" *ngIf="mainPageData[0].acf.visastartforetagetblock" class="pt-3">
<div class="container" style="padding-bottom:6rem; margin-top:4rem;">
<div class="row">

  <div class="col-lg-3 col-sm-12 desc-comp-offer wow fadeInUp" data-wow-delay="0.1s">
    <h2>Företaget</h2>
    <div class="heading-border-light"></div>
    <button title="Begär offert" class="btn btn-general btn-green" (click)="goto('/offert#offert')"  role="button">Begär offert</button>
    <button title="Vanliga frågor" class="btn btn-general btn-white" (click)="goto('/offert#offert')"  role="button">Vanliga frågor</button>
  </div>

    <div class="col-lg-3 col-sm-4 desc-comp-offer wow fadeInUp" data-wow-delay="0.2s" *ngFor="let itm of mainPageData[0].acf.listastartforetagetblock let i = index">
      <div class="desc-comp-offer-cont" (click)="goto(itm.acf.lank)">
        <div class="thumbnail-blogs">
            <img src="{{itm.acf.bild.url}}" class="img-fluid" alt="...">
        </div>
        <h3>{{itm.acf.rubrik}}</h3>
        <p class="desc" [innerHTML]="itm.acf.text"></p>
        <a routerLink="{{itm.acf.lank}}" title="kontakta oss" *ngIf="itm.acf.visa_lank"><i class="fa fa-arrow-circle-o-right pb-3"></i> {{itm.acf.lanktext}}</a>
      </div>
    </div>


</div>
</div>
</section>
