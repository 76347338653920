 <!--====================================================
                          Offert formulär  
  ======================================================--> 
  <!-- <section id="offert" class="contact-p1_  bg-gray" style="padding-bottom:3rem;padding-top:3rem; margin-left:0px; margin-right: 0px;" > -->
    <div class="container ">
      <div class="row  pt-5">
        <div class="col-md-12">
          <div class="contact-p1-cont">
            <h2>Skicka in din offertförfrågan</h2>
            <div class="heading-border-light"></div>          
          </div>
        </div>      
          
      </div>
    </div>
    <div class="container" >
        <form [formGroup]="OffertForm" class="row  pb-5 con-form">
          
          <div class="col-12 col-md-8">         
              <label></label>
              <input type="text" formControlName="offertName" name="offertName" placeholder="Namn" class="form-control" 
              [class.is-invalid]="OffertForm.get('offertName')?.invalid && OffertForm.get('offertName')?.touched">
              <div *ngIf="OffertForm.get('offertName')?.invalid && OffertForm.get('offertName')?.touched" class="ml-1 text-danger">* Du måste fylla i ett kontaktnamn</div>
              
              <label></label>
              <input type="text" formControlName="offertForetag" name="offertForetag" placeholder="Företag" class="form-control">
                            
              <label></label>
              <input type="text" formControlName="offertEmail" name="offertEmail" placeholder="E-post" class="form-control"
              [class.is-invalid]="OffertForm.get('offertEmail')?.invalid && OffertForm.get('offertEmail')?.touched"> 
              <div *ngIf="OffertForm.get('offertEmail')?.invalid && OffertForm.get('offertEmail')?.touched"  class="ml-1 text-danger">
                <span *ngIf="OffertForm.get('offertEmail')?.invalid">* Du måste ange en giltig E-postadress</span>
              </div>
              
              <label></label>         
              <select id="val-typ" formControlName="offertTyp" name="offertTyp" class="form-control" style="color:#717171;"
              [class.is-invalid]="OffertForm.get('offertTyp')?.invalid && OffertForm.get('offertTyp')?.touched">
                <option value="">Välj offerttyp</option>
                <option value="1">Takstolar</option>
                <option value="2">Stommar</option>
                <option value="3">Maskinhall</option>  
                <option value="4">Annat</option>               
            </select>        
            <span *ngIf="OffertForm.get('offertTyp')?.invalid && OffertForm.get('offertTyp')?.touched" class="ml-1 text-danger">* Du måste välja offerttyp </span>   
          </div>
          <div class="col-md-12">                      
            <textarea formControlName="offertUnderlag" name="offertUnderlag" id="offertUnderlag" placeholder="Underlag för offertförfrågan" class="form-control mb-0"
            [class.is-invalid]="OffertForm.get('offertUnderlag')?.invalid && OffertForm.get('offertUnderlag')?.touched"></textarea>    
            <div *ngIf="OffertForm.get('offertUnderlag')?.invalid && OffertForm.get('offertUnderlag')?.touched"  class="ml-1 text-danger">
              <span *ngIf="OffertForm.get('offertUnderlag')?.invalid">* Du måste fylla i underlag för offertförfrågan</span>
            </div>        
          </div>
  
          <div class="col-12 col-md-8">   
            <div class="panel panel-default  mt-4">
              <div class="input-group image-preview">
                <input placeholder="" type="text" formControlName="offertFile" name="offertFile" class="form-control image-preview-filename" [value]="filnamn" (drop)="onDropFile($event)" (dragover)="onDragOverFile($event)">
                <span class="input-group-btn"> 
                  
                  <button class="btn btn-default image-preview-input" *ngIf="clearfile" >                     
                    <span class="image-preview-input-title" (click)="onRemove($event)">Ta bort</span>                    
                  </button> 
                  <div class="btn btn-default image-preview-input" *ngIf="!clearfile">                     
                    <span class="image-preview-input-title" >Ladda upp underlag</span>
                    <input type="file" accept="image/png, image/jpeg, image/gif" name="input-file-preview" formControlName="offertUpload" name="offertUpload" (change)="onChange($event)"/>
                  </div> 
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-12 sub-but" >          
            <button class="btn btn-general btn-white mt-4" (click)="submitForm()" role="button">Skicka in </button>
            <div class="ml-1 mt-4 text-success greenboxBorder" *ngIf="inskickatForm" >                     
              <h4 class="text-success">Tack för din offertförfrågan!</h4>              
              <h5 class="text-success">Vi återkommer inom kort!</h5>
              <button class="btn btn-green" (click)="fadeout()">Ok</button>
            </div> 
          </div>
  
        </form>

        <!-- {{OffertForm.value | json}}
        {{FDObj | json}} -->
        
    </div>
  <!-- </section> -->
